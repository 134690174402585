import { css } from '@emotion/react';
import swirl from '../assets/doodles/feature-title.svg';
import mark from '../assets/doodles/feature-mark.svg';
import crown from '../assets/doodles/feature-crown.svg';
import longmark from '../assets/doodles/long-blue-underline.png';
import { breakpoints, colors, dimensions } from './variables';

export const FeatureTitleSwirl = css`
  position: relative;

  &:after {
    content: '';
    width: 60px;
    height: 65px;
    position: absolute;
    top: -30px;
    left: -42px;
    z-index: -1;
    background: url(${swirl}) no-repeat 0 0;
    background-size: contain;
    @media screen and (max-width: 768px) {
      background-size: 85%;left: -24px;
    }
  }
`;


export const FeatureParagraphMarkLong = css`
  position: relative;

  &:before {
    content: '';
    width: 134px;
    height: 17px;
    position: absolute;
    bottom: -13px;
    right: 28px;
    z-index: -1;
    background: url(${longmark}) no-repeat 0 0;
    background-size: contain;

    @media screen and (max-width: 414px) {
      //right: 238px;
    }

    @media screen and (max-width: 400px) {
      right: 23px;
    }

    @media screen and (max-width: 280px) {
      right: 0px;
    }
  }
  // &:after {
  //   content: '';
  //   width: 34px;
  //   height: 17px;
  //   position: absolute;
  //   bottom: -14px;
  //   left: 0;
  //   z-index: -1;
  //   background: url(${longmark}) no-repeat 0 0;
  //   background-size: contain;

  //   @media screen and (max-width: 414px) {
  //     left: -290px;
  //   }
  //   @media screen and (max-width: 400px) {
  //     left: 0px;
  //   }
  //   @media screen and (max-width: 280px) {
  //     left: -170px;
  //   }
  // }
`;

export const FeatureParagraphMark = css`
  position: relative;

  &:before {
    content: '';
    width: 34px;
    height: 17px;
    position: absolute;
    bottom: -13px;
    right: 48px;
    z-index: -1;
    background: url(${mark}) no-repeat 0 0;
    background-size: contain;

    @media screen and (max-width: 414px) {
      right: 238px;
    }

    @media screen and (max-width: 400px) {
      right: 48px;
    }

    @media screen and (max-width: 280px) {
      right: 108px;
    }
  }
  &:after {
    content: '';
    width: 34px;
    height: 17px;
    position: absolute;
    bottom: -14px;
    left: 0;
    z-index: -1;
    background: url(${mark}) no-repeat 0 0;
    background-size: contain;

    @media screen and (max-width: 414px) {
      left: -290px;
    }
    @media screen and (max-width: 400px) {
      left: 0px;
    }
    @media screen and (max-width: 280px) {
      left: -170px;
    }
  }
`;

export const FeatureParagraphCrown = css`
  position: relative;

  &:after {
    content: '';
    width: 32px;
    height: 22px;
    position: absolute;
    top: -24px;
    right: 24px;
    z-index: -1;
    background: url(${crown}) no-repeat 0 0;
    background-size: contain;
  }
`;

export const textCenter = css`
  text-align: center;
`;

export const container = css`
  width: 1170px;
  margin: 0 auto;

  @media screen and (max-width: 1170px) {
    width: 100%;
    padding: 20px;
  }
`;

export const para = css`
  color: #67717b;
  font-size: 20px;
  line-height: 1.5;
`;

export const oslBullets = css`
  list-style-type: none;
`;
